import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import type { IIdPathMapByPage, IManagerFormValue } from '@manager'

export const useManagerStore = defineStore('manager', () => {
  const idPathByPage = ref<IIdPathMapByPage>(new Map())
  const formValueMap = reactive<Record<number, IManagerFormValue>>({})

  return { idPathByPage, formValueMap }
})
